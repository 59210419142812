<template>
    <section class="exceptions-container">
        <date-picker
            class="full"
            format="DD [de] MMM YYYY"
            placeholder="Selecione o intervalo de datas"
            :clearable="false"
            :lang="langDatePicker"
            v-model="exception.days"
            @change="changeDates"
            range
        ></date-picker>
        
        <div v-for="(interval, index) in exception.intervals" :key="index">
            <div class="intervals">
                <the-mask
                    placeholder="Início"
                    :mask="['##:##']"
                    v-model="interval.start_time"
                    masked
                    class="form-control"
                    :class="isInvalidInterval() && 'error'"
                    @blur.native="updateConfig()"
                />
                <the-mask
                    placeholder="Fim"
                    :mask="['##:##']"
                    v-model="interval.end_time"
                    masked
                    class="form-control"
                    :class="isInvalidInterval() && 'error'"
                    @blur.native="updateConfig()"
                />
                <TimeDuration 
                    v-if="!hideDuration"
                    required
                    v-model="interval.duration_time"
                    :error="null"
                />
                <div>
                    <Trash
                        class="trash"
                        :disabled="!canEdit"
                        v-b-tooltip.hover title="Excluir intervalo"
                        @click="removeInterval(index)"
                    />
                </div>
            </div>
            <div v-if="!hideDuration" class="conflict">
                {{ hasClinicConflict(exception.days, interval.start_time, interval.end_time) }}
            </div>
        </div>
        <div class="actions">
            <div class="left-actions">
                <b-button 
                    variant="outline-danger"
                    @click="$emit('removeException')"
                >
                    Excluir exceção
                </b-button>
                <b-button 
                    variant="outline-secondary" 
                    @click="addInterval"
                >
                    Adicionar intervalo
                </b-button>
            </div>
            
            <b-button 
                variant="primary"
                :disabled="!isValid() || loading"
                @click="save"
            >
                Salvar
            </b-button>
        </div>
    </section>
</template>
<script>
import api from '../api'
import { mapGetters } from 'vuex'

export default {
    components: {
        TimeDuration: () => import('@/components/General/TimeDuration'),
        Trash: () => import('@/assets/icons/trash.svg'),
    },
    props: {
        exception: { 
            type: Object, 
            default: () => ({})
        },
        hideDuration: { type: Boolean, default: false }
    },
    mounted() {
        this.populateDays()
    },
    computed: {
        ...mapGetters('scheduleConfig', ['hasClinicConflict'])
    },
    data() {
        return {
            canEdit: true,
            loading: false,
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                }
            },
        }
    },
    methods: {
        isValidDate() {
            this.isValidDateFromClinic({})
        },
        changeDates(days) {
            this.exception.date_start = this.moment(days[0]).format('YYYY-MM-DD')
            this.exception.date_end = this.moment(days[1]).format('YYYY-MM-DD')
        },
        addInterval() {
            this.exception.intervals.push({
                start_time: '',
                end_time: '',
                duration_time: '',
            })
        },
        removeInterval(index) {
            this.exception.intervals.splice(index, 1)
        },
        isInvalidInterval() {},
        updateConfig() {},
        destroyInterval() {},
        isValid() {
            return this.exception.date_start && 
                this.exception.date_end && 
                this.exception.intervals?.length &&
                this.exception.intervals?.every(i => 
                    i.start_time && 
                    i.end_time && 
                    (this.hideDuration || i.duration_time) &&
                    (this.hideDuration || !this.hasClinicConflict(this.exception.days, i.start_time, i.end_time))
                )
        },
        async save() {
            this.loading = true
            const payload = {
                id: this.exception.id,
                schedule_config_id: this.exception.schedule_config_id,
                date_start: this.exception.date_start,
                date_end: this.exception.date_end,
                intervals: this.exception.intervals
            }
            try {
                payload.id ? 
                    await api.updateScheduleException(payload.id, payload) :
                    await api.createScheduleException(payload)
                this.$toast.success('Exceção salva com sucesso')
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        populateDays() {
            if (this.exception.date_start && this.exception.date_end && !this.exception.days?.length)
                this.exception.days = [this.moment(this.exception.date_start).toDate(), this.moment(this.exception.date_end).toDate()]
        }
    },
    watch: {
        exception: {
            handler() {
               this.populateDays()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.exceptions-header {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.empty {
    width: 100%;
    height: 400px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.exceptions-container {
    border: 1px solid var(--neutral-300);
    border-radius: 5px;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}
.intervals {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
}
.actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.5rem;
}
.left-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
.trash {
    width: 24px;
    stroke: var(--neutral-500);
    cursor: pointer;
}
.plus {
    width: 24px;
    fill: var(--neutral-500);
    cursor: pointer;
}
.hr {
    width: 100%;
}
.conflict {
    margin-top: 0.75rem;
    color: var(--orange);
    font-size: 12px;
}
</style>